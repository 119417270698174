<div class="grid-container">

    <div [hidden]="isMobile && selectedTab !='profile'">
  
      <!--
      <input type="file" #input2 hidden accept="image/*" (change)="changeMainImage($event)">
      <div class="image-wrapper" *ngFor="let i of mainImageRefresh" (click)=input2.click()
        matTooltip="Click to change image. Dimention: 1550 x 293.5 px.">
        <img class="cover-image" src="https://www.w3schools.com/howto/img_nature_wide.jpg" alt="Nature"
          *ngIf="!hasMainImage">
        <img class="cover-image" [src]="getMainImageUrl() | secure | async" alt="mainImage" *ngIf="hasMainImage">
      </div>
      -->
  
      <div class="profile-card">
  
        <input type="file" #input1 hidden accept="image/*" (change)="changeLogo($event)">
        <div *ngFor="let i of logoRefresh">
          <div class="logo-container mat-elevation-z2" (click)="input1.click()">
            <img class="image" src="../../../../assets/logo-final.png" alt="logo" class="image" *ngIf="!hasLogo">
            <img class="image" [src]="getLogoUrl() | secure | async" alt="logo" class="image" *ngIf="hasLogo">
            <div class="middle">
              <mat-icon>camera_alt</mat-icon>
              <div class="span">Change Logo</div>
            </div>
          </div>
        </div>
  
        <div class="text-block">
          <div class="name">{{entity?.orgname}}</div>
          <div class="designation">{{entity?.website}}</div>
          <div class="place">{{entity?.industry}}</div>
  
          <div class="social-block">
            <button mat-icon-button>
              <mat-icon svgIcon="instagram"></mat-icon>
            </button>
  
            <button mat-icon-button>
              <mat-icon svgIcon="twitter"></mat-icon>
            </button>
  
            <button mat-icon-button>
              <mat-icon svgIcon="facebook"></mat-icon>
            </button>
          </div>
        </div>
  
  
        <div class="edit-icon">
          <button (click)="modifyCompany(entityId)">
            <mat-icon class="edit-button">edit</mat-icon>
          </button>
        </div>

        <!--
        <div class="activity-icon">
          <button (click)="followUnfollow()">
            <img src="/assets/follow-small.svg">
            {{entity?.following ? 'Unfollow' : 'Follow'}}
          </button>
  
          <button [matMenuTriggerFor]="shareMenu">
            <img src="/assets/share-small.svg">Share
          </button>
  
          <button (click)="report()">
            <img src="/assets/report-small.svg">Report
          </button>
  
          <button [matMenuTriggerFor]="sendMenu">
            <img src="/assets/message-small.svg">Message
          </button>
        </div>
        -->

    </div>
  
 
    <div class="row content-section">
  
      <div class="leftcolumn entity-homepage-columns">
  
        <div id="newsfeed" [hidden]="isMobile && selectedTab!='newsfeed'">
  
          <div class="newsfeed-card">
  
            <div *ngFor="let newsfeed of newsfeeds" class="newsfeed">
              <app-newsfeed-card [newsfeed]=newsfeed [entityId]=this.entityId></app-newsfeed-card>
            </div>

          </div>
        </div>

  
      </div>
  
      <div class="rightcolumn entity-homepage-columns" [hidden]="isMobile && selectedTab !='content'">
  
        <div id="createcontent" class="create-content">
  
          <div class="newpost-card">
  
            <div class="start-test">
              <span>Create Content</span>
            </div>
  
            <div id="entity_dashboard_buttons">
              <div class="action">
                <button class="article-button" (click)="createArticle()">Article</button>
              </div>
  
              <div class="action">
                <button class="contest-button" (click)="createContest()">Contest</button>
              </div>
  
              <div class="action">
                <button class="project-button" (click)="createProject()">Project</button>
              </div>
            </div>
  
          </div>
  
        </div>
  
  
        <mat-tab-group *ngIf="contests.length" mat-align-tabs="center">
          <mat-tab label="Contests">
            <ol class="contest">
              <li *ngFor="let contest of contests">
                <h4 class="lable">{{contest.title}}</h4>
                <div class="text">{{contest.abstract}}</div>
              </li>
            </ol>
          </mat-tab>
  
          <mat-tab label="Projects">
  
            <ol class="contest">
              <li *ngFor="let project of projects">
                <h4 class="lable">{{project.title}}</h4>
                <div class="text">{{project.abstract}}</div>
              </li>
            </ol>
          </mat-tab>
  
          <mat-tab label="Articles">
            <ol class="contest">
              <li *ngFor="let article of articles">
                <h4 class="lable">{{article.title}}</h4>
                <div class="text">{{article.abstract}}</div>
              </li>
            </ol>
          </mat-tab>
        </mat-tab-group>
  
        <div class="poeple" *ngIf="people.length">
  
          <div fxLayout="row">
            <div class="icon-container">
              <mat-icon svgIcon="crown"></mat-icon>
            </div>
            <h3 class="people-header">People / Experts</h3>
          </div>
  
  
          <div class="widget">
            <div class="person" *ngFor="let person of people">{{person.userid}}</div>
          </div>
        </div>
  
      </div>
  
    </div>
  
  </div>
  
  <!-- <div class="footer">
    <div class="text">A-GORA GRAPH LTD © Copywrite 2018</div>
  </div> -->
  
  
  <mat-menu #shareMenu="matMenu" class="custom-mat-menu">
    <form [formGroup]="shareForm" (click)="$event.stopPropagation()">
      <p>Share to:</p>
      <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
        <ng-select [items]="myShareSets" class="custom-ng-select" appearance="outline" appendTo="body" bindValue="value"
          bindLabel="name" [clearable]="true" formControlName="person" [notFoundText]="'No Results'"
          typeToSearchText="Search" placeholder="Person">
        </ng-select>
  
        <ng-select [items]="myNetwork" class="custom-ng-select" appearance="outline" appendTo="body" bindValue="uuid"
          bindLabel="name" [clearable]="true" formControlName="mynetwork" [notFoundText]="'No Results'"
          typeToSearchText="Search" placeholder="My Network">
        </ng-select>
  
        <button type="button" mat-stroked-button (click)="share()">Share</button>
      </div>
    </form>
  </mat-menu>
  
  <mat-menu #sendMenu="matMenu" class="custom-mat-menu">
    <form [formGroup]="messageForm" (click)="$event.stopPropagation()">
      <p>Send to:</p>
      <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-form-field class="custom-form-field" appearance="outline">
          <mat-label>Subject</mat-label>
          <input matInput placeholder="Subject" formControlName="subject">
        </mat-form-field>
  
        <mat-form-field class="custom-form-field" appearance="outline">
          <mat-label>Mesage</mat-label>
          <textarea matInput placeholder="Message" formControlName="message"></textarea>
        </mat-form-field>
  
        <button type="button" mat-stroked-button (click)="sendMessage()">Send</button>
      </div>
    </form>
  </mat-menu>
  
  
  <nav class="bottom-nav">
    <a class="bottom-nav__destination bottom-nav__destination--active" (click)="newsFeed()">
      <mat-icon svgIcon="polls"></mat-icon>
      <span class="bottom-nav__label">News Feed</span>
    </a>
  
    <a class="bottom-nav__destination" (click)="createContent()">
      <div class="btm_nav_icon">
        <mat-icon>note_alt</mat-icon>
        <span class="bottom-nav__label">Content</span>
      </div>
    </a>
  
    <a class="bottom-nav__destination" (click)="profile()">
      <mat-icon svgIcon="user"></mat-icon>
      <span class="bottom-nav__label">Profile</span>
    </a>
  </nav>
  