import {Component, ElementRef, Inject, OnInit} from '@angular/core';
import {DashboardService} from '../../_services/dashboard.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ManageMessagesComponent } from '../manage-messages/manage-messages.component';

@Component({
  selector: 'app-mail-dialog',
  templateUrl: './mail-dialog.component.html',
  styleUrls: ['./mail-dialog.component.scss']
})
export class MailDialogComponent implements OnInit {

  profile;
  entityId;
  contacts;
  openedChats = [];
  numChats = 0;

  profilePictureUrl = `${environment.API_HOST}/files/profilepicture/`;

  constructor(private service: DashboardService,
              private dialogRef: MatDialogRef<MailDialogComponent>,
              public hostElement: ElementRef,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) private data: any) {

    this.entityId = data.for_entity;
  }

  ngOnInit(): void {

    this.service.postContacts(this.entityId).subscribe(resp => {
      this.contacts = resp.data;
    });
    
    // this.getMessages();
    this.service.getUserProfile().subscribe(resp => {
      this.profile = resp.data;
    });

  }

  // getMessages(): any {
  //   this.service.getMessages().subscribe(resp => {
  //     this.messageCount = resp['data']['messagecount'];
  //     this.messages = resp['data']['messages'];
  //   });
  // }

  getUrl(id): any {
    if( id ){
      return this.profilePictureUrl + id;
    }else{
      return '';
    }
    
  }


  openMessageDialog(entity): any {
    if( (!this.openedChats[entity] || this.openedChats[entity]==0) && this.numChats<5){
      this.openedChats[entity] = 1;
      this.numChats++;
      if(this.numChats<6){
        let from_left = 18*this.numChats;
        this.dialog.open(ManageMessagesComponent, {
          hasBackdrop: false,
          autoFocus: true,
          position: { bottom: '2px', right: from_left+'%' },
          width: '15%',
          panelClass: 'custom-dialog',
          data: { entityid: this.entityId, receiverid: entity}
        }).afterClosed().subscribe(rs => { // New line
          this.openedChats[rs] = 0;
          this.numChats--;
        });
      }else{
        this.numChats--;
      }
    }
    

  }

}
