import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

declare let fbq: Function; // Declare the global Facebook Pixel function
declare const hj: Function; // Declare the global Hotjar function


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private router: Router) {}

  title = 'frontend';
  
  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        fbq('track', 'PageView');
        hj('stateChange', event.urlAfterRedirects);
      }
    });
  }
}
