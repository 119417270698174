

<div *ngIf="profile">

    <mat-card-header>
        <mat-card-title style="width: 15%">
            <img [src]="getUrl(profile?.profilepicture) | secure | async" alt="photo" class="profile-pic" *ngIf="profile?.profilepicture">
        </mat-card-title>
        <span fxFlex></span>
        <div style="width: 45%;">
            <div class="name">
                {{profile?.intro[0].firstname}} {{profile?.intro[0].lastname}}
            </div>
            <div>
                {{profile?.intro[0].title}}
            </div>
        </div>

        <div class="activity-icon">
            <button (click)="followUnfollow()">
              <img src="/assets/follow-small.svg">
              {{entity?.following ? 'Unfollow' : 'Follow'}}
            </button>
    
            <button [matMenuTriggerFor]="shareMenu">
              <img src="/assets/share-small.svg">Share
            </button>
    
            <button (click)="report()">
              <img src="/assets/report-small.svg">Report
            </button>
    
            <button (click)="openMessageDialog()">
              <img src="/assets/message-small.svg">Message
            </button>
        </div>
    </mat-card-header>

    <mat-menu #shareMenu="matMenu" class="custom-mat-menu">
        <form [formGroup]="shareForm" (click)="$event.stopPropagation()">
          <p>Share to:</p>
          <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
            <ng-select [items]="myShareSets" class="custom-ng-select" appearance="outline" appendTo="body" bindValue="value"
              bindLabel="name" [clearable]="true" formControlName="person" [notFoundText]="'No Results'"
              typeToSearchText="Search" placeholder="Person">
            </ng-select>
      
            <ng-select [items]="myNetwork" class="custom-ng-select" appearance="outline" appendTo="body" bindValue="uuid"
              bindLabel="name" [clearable]="true" formControlName="mynetwork" [notFoundText]="'No Results'"
              typeToSearchText="Search" placeholder="My Network">
            </ng-select>
      
            <button type="button" mat-stroked-button (click)="share()">Share</button>
          </div>
        </form>
    </mat-menu>
    
    <!--  <mat-menu #sendMenu="matMenu" class="custom-mat-menu">
        <form [formGroup]="messageForm" (click)="$event.stopPropagation()">
          <p>Send to:</p>
          <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
            <mat-form-field class="custom-form-field" appearance="outline">
              <mat-label>Subject</mat-label>
              <input matInput placeholder="Subject" formControlName="subject">
            </mat-form-field>
      
            <mat-form-field class="custom-form-field" appearance="outline">
              <mat-label>Mesage</mat-label>
              <textarea matInput placeholder="Message" formControlName="message"></textarea>
            </mat-form-field>
      
            <button type="button" mat-stroked-button (click)="sendMessage()">Send</button>
          </div>
        </form>
    </mat-menu>  -->
    

    <div>
        {{profile?.intro[0].summary}}
    </div>


    <br/>

    <h3 class="headers"> Experience </h3>

    <div *ngFor="let exp of profile?.experience">
        <div class="row">
            <div class="sub-header">
                {{exp.position}}
            </div>
            <div class="daterange" *ngIf="exp.iscurrent == 0">
                {{exp.frommonth}} {{exp.fromyear}} - {{exp.tomonth}} {{exp.toyear}}
            </div>
            <div class="daterange" *ngIf="exp.iscurrent == 1">
                {{exp.frommonth}} {{exp.fromyear}} - present
            </div>
        </div>
        <div>
            {{exp.company}}
        </div>
        <mat-divider></mat-divider>
        <br/>
    </div>


    <br/>

    <h3 class="headers"> Education </h3>

    <div *ngFor="let edu of profile?.education">
        <div class="row">
            <div class="sub-header">
                {{edu.degree}}  {{edu.fieldofstudy}}
            </div>
            <div class="daterange">
                {{edu.frommonth}} {{edu.fromyear}} - {{edu.tomonth}} {{edu.toyear}}
            </div>
        </div>
        <div>
            {{edu.country}}
        </div>
        <mat-divider></mat-divider>
        <br/>
    </div>


    <br/>

    <h3 class="headers" [matTooltip]="'here comes an info box on hover'"> A-gora professional stats </h3>
    <div class="containers">
        <h4>Level: expert / 2000 </h4>
        <mat-divider></mat-divider>

        <h4>Business skills </h4>
        <table>
            <tr>
                <th>Counter 1: </th>
                <th>Counter 2: </th>
                <th>Counter 3: </th>
            </tr>
            <tr>
                <th>Counter 4: </th>
                <th>Counter 5: </th>
                <th>Counter 6: </th>
            </tr>
            <tr>
                <th>Counter 7: </th>
            </tr>
        </table>
        <mat-divider></mat-divider>

        <h4>Soft skills </h4>
        <table>
            <tr>
                <th>Counter 1: </th>
                <th>Counter 2: </th>
                <th>Counter 3: </th>
            </tr>
            <tr>
                <th>Counter 4: </th>
                <th>Counter 5: </th>
                <th>Counter 6: </th>
            </tr>
            <tr>
                <th>Counter 7: </th>
            </tr>
        </table>
    </div>


    <br/>

    <h3 class="headers" [matTooltip]="'here comes an info box on hover'"> Achievement’s portfolio </h3>
    <div class="containers">
        <ul>
            <li>Projects executed: …</li>
            <li>Articles published: …</li>
            <li>Contests won: …</li>
        </ul>
        <mat-divider></mat-divider>
        <h4>Article title ... </h4>
        <mat-divider></mat-divider>
        <h4>Project title ... </h4>
    </div>


    <br/>

    <h3 class="headers" [matTooltip]="'here comes an info box on hover'"> Other achievements’ portfolio </h3>
    <div *ngFor="let portf of profile?.portfolio">
        <div>
            {{portf.title}}
        </div>
        <div>
            {{portf.description}}
        </div>
        <mat-divider></mat-divider>
        <br/>
    </div>

</div>







<div *ngIf="entity">

    <mat-card-header>
        <mat-card-title style="width: 15%">
            <img [src]="getUrl(entity?.logofileid) | secure | async" alt="photo" class="profile-pic" *ngIf="entity?.logofileid">
        </mat-card-title>
        <span fxFlex></span>
        <div style="width: 45%;">
            <div class="name">
                {{entity?.orgname}}
            </div>
            <div>
                {{entity?.website}}
            </div>
        </div>

        <div class="activity-icon">
            <button (click)="followUnfollow()">
              <img src="/assets/follow-small.svg">
              {{entity?.following ? 'Unfollow' : 'Follow'}}
            </button>
    
            <button [matMenuTriggerFor]="shareMenu">
              <img src="/assets/share-small.svg">Share
            </button>
    
            <button (click)="report()">
              <img src="/assets/report-small.svg">Report
            </button>
    
            <button (click)="openMessageDialog()">
              <img src="/assets/message-small.svg">Message
            </button>
        </div>
    </mat-card-header>

    <mat-menu #shareMenu="matMenu" class="custom-mat-menu">
        <form [formGroup]="shareForm" (click)="$event.stopPropagation()">
          <p>Share to:</p>
          <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
            <ng-select [items]="myShareSets" class="custom-ng-select" appearance="outline" appendTo="body" bindValue="value"
              bindLabel="name" [clearable]="true" formControlName="person" [notFoundText]="'No Results'"
              typeToSearchText="Search" placeholder="Person">
            </ng-select>
      
            <ng-select [items]="myNetwork" class="custom-ng-select" appearance="outline" appendTo="body" bindValue="uuid"
              bindLabel="name" [clearable]="true" formControlName="mynetwork" [notFoundText]="'No Results'"
              typeToSearchText="Search" placeholder="My Network">
            </ng-select>
      
            <button type="button" mat-stroked-button (click)="share()">Share</button>
          </div>
        </form>
    </mat-menu>
    
    <!--  <mat-menu #sendMenu="matMenu" class="custom-mat-menu">
        <form [formGroup]="messageForm" (click)="$event.stopPropagation()">
          <p>Send to:</p>
          <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
            <mat-form-field class="custom-form-field" appearance="outline">
              <mat-label>Subject</mat-label>
              <input matInput placeholder="Subject" formControlName="subject">
            </mat-form-field>
      
            <mat-form-field class="custom-form-field" appearance="outline">
              <mat-label>Mesage</mat-label>
              <textarea matInput placeholder="Message" formControlName="message"></textarea>
            </mat-form-field>
      
            <button type="button" mat-stroked-button (click)="sendMessage()">Send</button>
          </div>
        </form>
    </mat-menu>  -->


    <div>
        {{entity?.description}}
    </div>

    <div>
        {{entity?.orgtype}}
        <br/>
        {{entity?.industry}}
        <br/>
        {{entity?.legaltype}}
    </div>

</div>


