<div style="padding: 20px">
  <h3 style="font-weight: bold; padding-left: 20px">Search By Category</h3>
  <div
    fxLayout="row"
    fxLayoutAlign="center"
    fxLayoutGap="20px"
    class="browse-categories"
  >
    <div *ngFor="let category of categories">
      <button
        mat-stroked-button
        style="border: 1px solid #008d91"
        (click)="filterByCategory(category)"
      >
        {{ category }}
      </button>
    </div>
  </div>
</div>
