import { Component, Inject, OnInit } from '@angular/core';
//import { AfterViewChecked, ElementRef, ViewChild} from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardService } from '../../_services/dashboard.service';
import { MailDialogComponent } from '../mail-dialog/mail-dialog.component';

@Component({
  selector: 'app-manage-messages',
  templateUrl: './manage-messages.component.html',
  styleUrls: ['./manage-messages.component.scss']
})
export class ManageMessagesComponent implements OnInit  {

  messageResponse;
  messagesCount;
  messages;
  profile;
  receiverId;
  senderId;
  allMessages;
  openedChats;
  numChats;
  messageInput;
  lastMessageID;
  readNewMessages;

  messageValue = '';

  constructor(
    private service: DashboardService,
    private dialogRef: MatDialogRef<MailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.receiverId = data.receiverid;
    this.senderId = data.entityid;
  }

  ngOnInit(): void {

    this.dialogRef.updateSize('20%', '40%');

    this.service.postProfileInfo(this.receiverId).subscribe(resp => {
      this.profile = resp.data;
    });

    this.service.getUserProfile().subscribe(resp => {
      this.service.getChatMessages(this.senderId, this.receiverId).subscribe(resp => {
        this.allMessages = resp.data.messages;
        if( this.allMessages.length > 0 ){
          this.lastMessageID = this.allMessages[this.allMessages.length-1].messid;
        }else{
          this.allMessages = [];
        }
      });
    });

    this.scrollToBottom();

    this.readNewMessages = setInterval(() => this.getNewMessages(), 5000);
    
  }


  getNewMessages(): void {
    this.service.getChatMessagesAfterID(this.senderId, this.receiverId,this.lastMessageID).subscribe(resp => {
      if( resp.data.messages.length > 0 ){
        for( let b=0; b<resp.data.messages.length; b++){
          this.allMessages.push(resp.data.messages[b]);
          this.lastMessageID = resp.data.messages[b].messid;
        }
      }
    });
  }

  ngOnDestroy(): any {
    clearInterval(this.readNewMessages);
  }


  closeChat(entityid): any {
    this.dialogRef.close(entityid);
  }


  scrollToBottom(): void {
    let el = (document.getElementById("chatmessages") as HTMLFormElement);
    console.log("scrolling: ", el);
    //this.chatmessages.nativeElement.scrollTop = this.chatmessages.nativeElement.scrollHeight;
  } //*/

  
  sendMessage(value: string, inputid: string): any {

    this.messageValue = value;

    if( value.length > 0 ){
      this.service.postNewMessage(this.senderId, this.receiverId, this.messageValue).subscribe(resp => {
        this.allMessages.push(resp.data);
        this.lastMessageID = resp.data.messid;
        let el = (document.getElementById(inputid) as HTMLFormElement);
        el.value = "";
      });
    }

  }

}
