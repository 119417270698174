
<mat-card-header>

  <mat-card-title class="header">
    <div *ngIf="notificationCount == 1; else elseBlock">One unread notification.</div>
    <ng-template #elseBlock>{{notificationCount}} unread notifications:</ng-template>
  </mat-card-title>

  <span fxFlex></span>

  <button mat-button (click)="markAllRead()">Mark all as read</button>

</mat-card-header>

<br/>


<div *ngFor="let notification of notifications" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center" #lastElement>

  <!-- <div *ngIf="notification.profilepicture">
    <img [src]="getUrl(notification.profilepicture) | secure | async" alt="photo"
         style="width: 50px;height: 50px">
  </div>  -->

  <div *ngIf="!notification.is_read" class="notification-unread">
    <div>
      <h6 class="notification-header">{{notification.subject}}</h6>
      <p class="date">{{notification.ts | date: 'MMM d, y, h:mm a'}}</p>
    </div>

    <div class="button">
      <button mat-icon-button (click)="markRead(notification.notifid)" [matTooltip]="'Mark as read'"><mat-icon>done</mat-icon></button>
    </div>
  </div>


  <div *ngIf="notification.is_read" class="notification-read">
    <div>
      <h6 class="notification-header">{{notification.subject}}</h6>
      <p class="date">{{notification.ts | date: 'MMM d, y, h:mm a'}}</p>
    </div>
  </div>

</div>

