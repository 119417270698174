import { Component, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { DashboardService } from '../../_services/dashboard.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ManageMessagesComponent } from '../manage-messages/manage-messages.component';
import { MailDialogComponent } from '../mail-dialog/mail-dialog.component';

@Component({
  selector: 'app-expert-public-profile',
  templateUrl: './expert-public-profile.component.html',
  styleUrls: ['./expert-public-profile.component.scss']
})
export class ExpertPublicProfileComponent implements OnInit {

  entityId;
  entity;
  profile;
  profilePictureUrl = `${environment.API_HOST}/files/profilepicture/`;
  @ViewChildren(MatMenuTrigger) trigger: QueryList<MatMenuTrigger>;
  shareForm: FormGroup;
  messageForm: FormGroup;
  messages;
  messageResponse;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DashboardService,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ExpertPublicProfileComponent>,
    private mailDialogRef: MatDialogRef<MailDialogComponent>
  ) { 

    this.shareForm = fb.group({
      person: [null],
      mynetwork: [null]
    });

    this.messageForm = fb.group({
      subject: [''],
      message: ['']
    });

  }

  ngOnInit(): void {
    this.entityId = this.data.entityId;

    this.service.postProfileInfo(this.entityId).subscribe(resp => {
      if( resp.data.expert )
        this.profile = resp.data.expert;
      else {
        this.entity = resp.data.entity;
        this.getEntity();
      }

    });

  }

  getUrl(id): any {
    return this.profilePictureUrl + id;
  }

  getEntity(): any {
    this.service.getEntity(this.entityId).subscribe(resp => {
      this.entity = resp.data[0];
    });
  }


  followUnfollow(): any {
    if (this.entity.following) {
      this.service.unfollow(this.entityId).subscribe(() => this.entity.following = !this.entity.following);
    } else {
      this.service.follow(this.entityId).subscribe(() => this.entity.following = !this.entity.following);
    }
  }

  report(): any {
    this.service.reportAbuse(this.entityId).subscribe();
  }

  share(trigger?): any {
    trigger.closeMenu()
    this.service.entityShare(this.entityId, this.shareForm.value).subscribe(() => {
      // this.trigger.closeMenu();
      this.trigger.toArray()[0].closeMenu();
      this.snackbar.open('Shared Successfully!', 'Close', { duration: 3000, panelClass: 'success-snackbar' });
      this.shareForm.reset();
    }, () => {
      this.snackbar.open('Sharing Failed!', 'Close', { duration: 3000, panelClass: 'danger-snackbar' });
    });
  }

  /*sendMessage(): any {
    this.service.message(this.entityId, this.messageForm.value).subscribe(() => {
      // this.trigger.closeMenu();
      this.trigger.toArray()[1].closeMenu();
      this.snackbar.open('Message Sent!', 'Close', { duration: 3000, panelClass: 'success-snackbar' });
      this.messageForm.reset();
    }, () => {
      this.snackbar.open('Message Failed To Send!', 'Close', { duration: 3000, panelClass: 'danger-snackbar' });
    });
  }*/

  openMessageDialog(): any {

    this.dialogRef.close();

    this.service.getMessages().subscribe(resp => {
      this.messageResponse = resp;

      this.mailDialogRef = this.dialog.open(MailDialogComponent, {
        data: { messages: this.messageResponse, entityid: this.entityId },
        hasBackdrop: false,
        panelClass: 'custom-dialog',
        position: { top: '6%', right: '5%' },
        autoFocus: false,
      });

      this.dialog.open(ManageMessagesComponent, {
        hasBackdrop: false,
        autoFocus: false,
        position: { bottom: '2px', right: '25%' },
        panelClass: 'custom-dialog',
        data: { messages: this.messages, entityid: this.entityId}
      });

    });
    
  }

}
