
<div class="message-header" fxLayout="row" fxLayoutAlign="space-between center">

  <mat-form-field>
    <input type="text" matInput placeholder="Search">
    <button matSuffix mat-button><mat-icon >search</mat-icon></button>
  </mat-form-field>

  <button mat-icon-button class="close-button" [mat-dialog-close]="true" id="close-button-messages">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</div>


<!--  <div *ngFor="let message of messages" class="message-unread" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
  <div>
    <img [src]="getUrl(message.profilepicture) | secure | async" alt="photo"
         style="width: 50px;height: 50px">
  </div>
  <div>
    <h6 style="font-size: .875rem; color:#39527b;margin-bottom: 2px">{{message.subject}}</h6>
    <p class="message-date">{{message.ts | date: 'MMM d, y, h:mm a'}}</p>
  </div>
</div>  -->


<!--  <div>

  <div class="profile-wrapper" fxLayout="row" fxLayoutGap.xs="5px" fxLayoutAlign.xs="start">
    <div class="profile-pic">
      <img class="image" [src]="profilePictureUrl | secure | async">
    </div>

    <div class="message-info" style="cursor: pointer;" (click)="openMessageDialog()">
      <div class="name">{{profile?.firstname}} {{profile?.lastname}}</div>
      <div class="message">  some text i wrote to myself....</div>
    </div>

  </div>

  <mat-divider></mat-divider>

</div>  -->



<div>

  <div class="profile-wrapper" *ngFor="let expert of contacts?.experts" fxLayout="row" fxLayoutGap.xs="5px" fxLayoutAlign.xs="start">
    <div class="profile-pic">
      <img class="image" [src]="getUrl(expert?.profilepicture) | secure | async">
    </div>

    <div class="message-info" style="cursor: pointer;" (click)="openMessageDialog(expert.id)">
      <div class="name">{{expert?.firstname}} {{expert?.lastname}}</div>
      <div class="message">  last message....</div>
    </div>

  </div>

  <mat-divider></mat-divider>

</div>


<div>

  <br/><br/><br/>
  <h4> Organizations: </h4>

  <div class="profile-wrapper" *ngFor="let entity of contacts?.entities" fxLayout="row" fxLayoutGap.xs="5px" fxLayoutAlign.xs="start">
    <div class="profile-pic">
      <img class="image" [src]="getUrl(entity?.logofileid) | secure | async">
    </div>

    <div class="message-info" style="cursor: pointer;" (click)="openMessageDialog(entity.eid)">
      <div class="name">{{entity?.orgname}}</div>
      <div class="message">  last message....</div>
    </div>

  </div>

  <mat-divider></mat-divider>

</div>





