import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProfileService } from '../../../profile/_services/profile.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { DashboardService } from '../../_services/dashboard.service';
import { MatIconRegistry } from '@angular/material/icon';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

import { DialogConfirmComponent } from '../../../common/dialog-confirm/dialog-confirm.component';

import {environment} from '../../../../environments/environment';
import { ExpertPublicProfileComponent } from '../expert-public-profile/expert-public-profile.component';
import { MentorPublicProfileComponent } from '../mentor-public-profile/mentor-public-profile.component';
import { MatDialog } from '@angular/material/dialog';



@Component({
  selector: 'app-newsfeed-card',
  templateUrl: './newsfeed-card.component.html',
  //template: 'it should work now',
  styleUrls: ['./newsfeed-card.component.scss'],
  providers: [DialogConfirmComponent]
})

export class NewsfeedCardComponent implements OnInit {

  @Input() newsfeed;
  @Input() entityId;
  @ViewChildren(MatMenuTrigger) trigger: QueryList<MatMenuTrigger>;

  url = `${environment.API_HOST}/files/download/`;
  profilePictureUrl = `${environment.API_HOST}/files/profilepicture/`;
  sendForm: FormGroup;
  shareForm: FormGroup;
  commentForm: FormGroup;

  myShareSets;
  myNetwork;
  comments: Array<any>;

  numberOfLikes: number = 0;


  constructor(  private router: Router,
                private route: ActivatedRoute,
                private snackbar: MatSnackBar,
                private userService: ProfileService,
                private service: DashboardService,
                public dialog: MatDialog,
                private fb: FormBuilder,
                private matIconRegistry: MatIconRegistry,
                domSanitizer: DomSanitizer,
                private confirmDialog: DialogConfirmComponent
            ) { 
              this.sendForm = fb.group({
                userid: [null],
              });
          
              this.shareForm = fb.group({
                mynetwork: [null]
              });
          
              this.commentForm = fb.group({
                comment: ['']
              });
          
              this.matIconRegistry.addSvgIcon("filter", domSanitizer.bypassSecurityTrustResourceUrl("/assets/filter.svg"))
              this.matIconRegistry.addSvgIcon("folder", domSanitizer.bypassSecurityTrustResourceUrl("/assets/folder.svg"))
              this.matIconRegistry.addSvgIcon("postSettings", domSanitizer.bypassSecurityTrustResourceUrl("/assets/post-settings.svg"))
              this.matIconRegistry.addSvgIcon("like", domSanitizer.bypassSecurityTrustResourceUrl("/assets/like.svg"))
              this.matIconRegistry.addSvgIcon("liked", domSanitizer.bypassSecurityTrustResourceUrl("/assets/liked.svg"))
              this.matIconRegistry.addSvgIcon("comment", domSanitizer.bypassSecurityTrustResourceUrl("/assets/comment.svg"))
              this.matIconRegistry.addSvgIcon("share", domSanitizer.bypassSecurityTrustResourceUrl("/assets/share.svg"))
              this.matIconRegistry.addSvgIcon("send", domSanitizer.bypassSecurityTrustResourceUrl("/assets/send.svg"))
              this.matIconRegistry.addSvgIcon("post-image", domSanitizer.bypassSecurityTrustResourceUrl("/assets/post-image.svg"))
  }

  ngOnInit(): void {
    this.getMyShareSets();
    this.getMyNetwork();
    this.numberOfLikes = this.newsfeed.entity.likescount;
  }

  readNewsContent(id, type, picture, names, summary): any {
    if (type === 'article') {
      this.router.navigate(['article/read', { id, entityId: this.entityId, newsfeed: JSON.stringify(this.newsfeed.entity), picture, names, summary }]).then();
    }else if( type === 'project' ) {
      this.router.navigate(['project/read', { id, entityId: this.entityId, newsfeed: JSON.stringify(this.newsfeed.entity) }]).then();
    }else{
      this.router.navigate(['contest/read', { id, entityId: this.entityId, newsfeed: JSON.stringify(this.newsfeed.entity) }]).then();
    }
  }

  manageNewsContent(id, type): any {
    if( type === 'project' ) {
      this.router.navigate(['project/manage', { id, entityId: this.entityId }]).then();
    } else if (type === 'contest' ) {
      this.router.navigate(['contest/manage', { id, entityId: this.entityId }]).then();
    }
  }

  public isNewsContestOrProject(newstype: any){
    if( newstype == 'contest' || newstype == 'project' ) return true;
  }
  
  public isNewsArticle(newstype: any){
    if( newstype == 'article' ) return true;
  }


  public isNewsContestApplicable(newstype: any){
    if( newstype == 'contest' ) return true;
  }

  public isNewsProjectApplicable(newstype: any){
    if( newstype == 'project' ) return true;
  }

  getUrl(id): any {
    return this.profilePictureUrl + id;
  }

  send(trigger): any {
    trigger.closeMenu()
    this.service.send(this.entityId, this.sendForm.value).subscribe(() => {
      this.trigger.toArray()[7].closeMenu();
      this.snackbar.open('Message Sent!', 'Close', { duration: 3000, panelClass: 'success-snackbar' });
      this.sendForm.reset();
    }, () => {
      this.snackbar.open('Message Failed To Send!', 'Close', { duration: 3000, panelClass: 'danger-snackbar' });
    });
  }

  share(trigger): any {
    trigger.closeMenu()
    this.service.newsfeedShare(this.entityId, this.shareForm.value).subscribe(() => {
      this.trigger.toArray()[6].closeMenu();
      this.snackbar.open('Shared Successfully!', 'Close', { duration: 3000, panelClass: 'success-snackbar' });
      this.shareForm.reset();
    }, () => {
      this.snackbar.open('Sharing Failed!', 'Close', { duration: 3000, panelClass: 'danger-snackbar' });
    });
  }

  getMyShareSets(): any {
    this.userService.getMyShareSets().subscribe(resp => this.myShareSets = resp.data.entities);
  }

  getMyNetwork(): any {
    this.userService.getMyNetwork({}).subscribe(resp => this.myNetwork = resp.data.entities);
  }

  getComments(id): any {
    this.service.getComments(this.newsfeed.objectid).subscribe(resp => {
      this.comments = resp.data;
    });
  }

  comment(id, trigger): any {
    trigger.closeMenu()
    this.service.postComment(this.newsfeed.objectid, this.commentForm.value).subscribe(resp => {
      this.snackbar.open('Comment Added!', 'Close', { duration: 3000, panelClass: 'success-snackbar' });
      this.commentForm.reset();
    }, () => {
      this.snackbar.open('Comment Failed!', 'Close', { duration: 3000, panelClass: 'danger-snackbar' });
    });
  }

  likeUnlike(newsfeed): any {
    if (newsfeed.entity.is_liked) {
      this.service.unlike(this.newsfeed.objectid, this.entityId).subscribe(resp => newsfeed.entity.is_liked = !newsfeed.entity.is_liked);
      this.numberOfLikes--;
    } else {
      this.service.like(this.newsfeed.objectid, this.entityId).subscribe(resp => newsfeed.entity.is_liked = !newsfeed.entity.is_liked);
      this.numberOfLikes++;
    }
  }

  applyButtonContest(contestid): any {

    this.confirmDialog.message = 'Are you sure you want to apply for this contest?';
    this.confirmDialog.openDialog((res) => {
      if (res) {
        this.router.navigate(['contest/application', {entityId: this.entityId, contestid: contestid}]).then();
      }
    });
  }

  applyButtonProject(projectid): any {
    this.confirmDialog.message = 'Are you sure you want to apply for this project?';
    this.confirmDialog.openDialog((res) => {
      if (res) {
        this.router.navigate(['project/application', {entityId: this.entityId, projectid: projectid}]).then();
      }
    });
  }

  openEntityDialog() {
    const entityid = this.route.snapshot.paramMap.get('entityId');
    if (!entityid) return;

    const isMentor = this.route.snapshot.paramMap.get('isMentor');

    if (this.dialog.openDialogs.length === 1) return;

    if (isMentor === 'true') {
      this.dialog.open(MentorPublicProfileComponent, { 
        width: '1800px',
        height: '900px',
        panelClass: 'mentor-public-profile',
        data: {entityId: entityid} 
      })
      .afterClosed()
      .subscribe(() => { });
    } else {
      this.dialog.open(ExpertPublicProfileComponent, { 
        width: '800px',
        height: '700px',
        data: {entityId: entityid} 
      })
      .afterClosed()
      .subscribe(() => { });
    }
  }

  openExpertProfile(entity): any {
    if (entity.is_mentor) {
      this.dialog.open(MentorPublicProfileComponent, { 
        width: '1800px',
        height: '900px',
        panelClass: 'mentor-public-profile',
        data: {entityId: entity.entityid} 
      })
      .afterClosed()
      .subscribe(() => { });
      this.router.navigate(['/dashboard', {entityId: entity.entityid, isMentor: 'true'}]).then();
    } else {
      this.dialog.open(ExpertPublicProfileComponent, { 
        width: '800px',
        height: '700px',
        data: {entityId: entity.entityid} 
      })
      .afterClosed()
      .subscribe(() => { });
      this.router.navigate(['/dashboard', {entityId: entity.entityid, isMentor: 'false'}]).then();
    }

  }

}
