<form [formGroup]="portfolioForm" (ngSubmit)="submit()">

  <input id="files" name="files" type="hidden" value="gkbj,">

  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h2 style="margin: 0">{{ data ? 'Edit Portfolio ' : 'Add Portfolio'}}</h2>
  </div>
  <mat-divider></mat-divider>
  <div style="margin-top: 20px">
    <mat-form-field fxFlex>
      <mat-label>Title</mat-label>
      <input matInput placeholder="Enter your title" formControlName="title">
    </mat-form-field>
  </div>

  <div>
    <mat-form-field fxFlex>
      <mat-label>Description</mat-label>
      <textarea matInput placeholder="Enter your description" formControlName="description"></textarea>
    </mat-form-field>
  </div>

  <input type="hidden" formControlName="files">

  <div *ngIf="allfiles">
    <div *ngFor="let file of allfiles; let i = index">
      {{file.filename}}
      <mat-icon (click)="removeSelectedFile(i)">close</mat-icon>
      <br/>
      <img [src]="getUrl(file.fileid) | secure | async" alt="photo" style="max-width: 100px;">
    </div>
  </div>

  <div><hr width="100%"></div>
  <div>
    <button mat-icon-button class="mobile-file" (click)="input.click()" type="button" [class.spinner]="loading">
      <mat-icon>attach_file</mat-icon>
    </button>
    
    <input #input class="hidden-input" type="file" accept="*/*" (change)="handleFileInput($event)">
    <button mat-flat-button class="desktop-file" (click)="input.click()" type="button" [class.spinner]="loading">
      <mat-icon>attach_file</mat-icon>
      Attach File
    </button>
  </div>


  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
    <button mat-stroked-button type="button" (click)="close()">Cancel</button>
    <button mat-raised-button color="primary" type="submit">Save</button>
  </div>

</form>
