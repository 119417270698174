<div style="text-align: end">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<button mat-button color="primary" (click)="input.click()">
  <mat-icon>image</mat-icon>
  Select File
</button>
<input type="file" (change)="fileChangeEvent($event)" style="display: none" #input accept="image/*"/>
<br/>
<br/>
<button mat-icon-button (click)="rotateLeft()" matTooltip="Rotate left">
  <mat-icon>rotate_left</mat-icon>
</button>
<button mat-icon-button (click)="rotateRight()" matTooltip="Rotate right">
  <mat-icon>rotate_right</mat-icon>
</button>
<button mat-icon-button (click)="flipHorizontal()" matTooltip="Flip horizontal">
  <mat-icon>swap_horiz</mat-icon>
</button>
<button mat-icon-button (click)="flipVertical()" matTooltip="Flip vertical">
  <mat-icon>swap_vert</mat-icon>
</button>
<button mat-icon-button (click)="toggleContainWithinAspectRatio()" matTooltip="Toggle aspect ratio">
  <mat-icon>aspect_ratio</mat-icon>
</button>
<button mat-icon-button (click)="resetImage()" matTooltip="Reset image">
  <mat-icon>crop_square</mat-icon>
</button>

<div fxLayout="row" fxLayoutAlign="center center" style="margin-top: 30px">
  <div fxFlex="50" fxLayout="row">
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true"
      [containWithinAspectRatio]="containWithinAspectRatio"
      [aspectRatio]="1 / 1"
      [resizeToWidth]="256"
      [cropperMinWidth]="128"
      [onlyScaleDown]="true"
      [roundCropper]="true"
      [canvasRotation]="canvasRotation"
      [transform]="transform"
      [alignImage]="'left'"
      [style.display]="showCropper ? null : 'none'"
      format="png"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady($event)"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
    <mat-slider
      *ngIf="showCropper"
      matTooltip="Rotate"
      vertical
      [max]="360"
      [min]="-360"
      [step]="1"
      [tickInterval]="1"
      [(ngModel)]="rotation" (valueChange)="updateRotation()">
    </mat-slider>
  </div>
  <div fxFlex="50" style="text-align: center">
    <img [src]="croppedImage" [style.border]="croppedImage ? '1px solid black' : 'none'"
         style="border-radius: 50%; max-width: 50%"/>
  </div>
</div>
<mat-slider
  *ngIf="showCropper"
  matTooltip="Zoom In/Out"
  [max]="2"
  [min]="0"
  [step]="0.1"
  [tickInterval]="0.1"
  [(ngModel)]="scale" (valueChange)="slideZoom($event)">
</mat-slider>
<div style="text-align: center">
  <mat-error *ngIf="error">{{errorMessage}}</mat-error>
</div>
<div style="text-align: end">
  <button mat-raised-button (click)="save()" color="primary" [disabled]="!showCropper || error">Save</button>
</div>


