<div _ngcontent-eer-c169="" class="newsfeed ng-star-inserted">

  <div *ngIf="isNewsArticle(newsfeed.type)">
    <mat-card-header class="like-status view-it" 
      (click)="readNewsContent(newsfeed.objectid,newsfeed.type)">
        <mat-card-title class="name" >
          {{newsfeed.headerline}}
        </mat-card-title>
        <span fxFlex></span>
        <button class="post-settings" mat-icon-button *ngIf="newsfeed.entity.editable"
          (click)="readNewsContent(newsfeed.objectid,newsfeed.type, newsfeed.entity.profilepicture, newsfeed?.entity.names, newsfeed?.entity.summary)">
          <mat-icon class="edit-button">edit</mat-icon>
        </button>

    </mat-card-header>
  </div>


  <div *ngIf="isNewsContestOrProject(newsfeed.type)">
    <mat-card-header class="like-status view-it"
      (click)="readNewsContent(newsfeed.objectid,newsfeed.type)">
      <mat-card-title class="name">
        {{newsfeed.headerline}}
      </mat-card-title>
      <span fxFlex></span>

      <button class="post-settings" mat-icon-button *ngIf="newsfeed.entity.editable"
        (click)="manageNewsContent(newsfeed.objectid,newsfeed.type)">
        <mat-icon class="settings-button">settings</mat-icon>
      </button>

      <button class="post-settings" mat-icon-button *ngIf="newsfeed.entity.editable"
        (click)="readNewsContent(newsfeed.objectid,newsfeed.type)">
        <mat-icon class="material-icons">visibility</mat-icon>
      </button>

    </mat-card-header>
  </div>


  <div class="post-image" style="min-height: 90px;">

    <div *ngIf="isNewsContestApplicable(newsfeed.type)">
      <div class="news_button">
        <button class="appl_button" (click)="applyButtonContest(newsfeed.objectid)" *ngIf="newsfeed.entity.editable == false && newsfeed.entity.openAppl == true">Apply</button>
        <div class="nws_feed_meta">Applications: <b>{{newsfeed?.applications}}</b></div>
        <div class="nws_feed_meta">Open until: <b>{{newsfeed?.openuntildate | date: 'mediumDate'}}</b></div>
      </div>
    </div>

    <div *ngIf="isNewsProjectApplicable(newsfeed.type)">
      <div class="news_button">
        <button class="appl_button" (click)="applyButtonProject(newsfeed.objectid)" *ngIf="newsfeed.entity.editable == false && newsfeed.entity.openAppl == true">Apply</button>
        <div class="nws_feed_meta">Applications: <b>{{newsfeed?.applications}}</b></div>
        <div class="nws_feed_meta">Open until: <b>{{newsfeed?.openuntildate | date: 'mediumDate'}}</b></div>
      </div>
    </div>

    <div (click)="openExpertProfile(newsfeed.entity)" style="cursor: pointer;">
      <img [src]="getUrl(newsfeed.entity.profilepicture) | secure | async" alt="photo"
      *ngIf="newsfeed.entity?.profilepicture">
      <div class="name">{{newsfeed?.entity.names}}</div>
      <div class="summary">{{newsfeed?.entity.headline}}</div>
    </div>
      
  </div>

  
  <hr class="tiny_seperator" >


  <div *ngIf="newsfeed.mainimage" (click)="readNewsContent(newsfeed.objectid,newsfeed.type)">
    <quill-view-html [content]="newsfeed.showtext" class="custom-pointer-cursor"
      (click)="readNewsContent(newsfeed.objectid,newsfeed.type)"></quill-view-html>
  </div>

  <div *ngIf="!newsfeed.mainimage" (click)="readNewsContent(newsfeed.objectid,newsfeed.type)">
    <quill-view-html [content]="newsfeed.showtext" class="custom-pointer-cursor"
      (click)="readNewsContent(newsfeed.objectid,newsfeed.type)"></quill-view-html>
  </div>


  <div *ngIf="newsfeed.mainimage" class="content-image"
    (click)="readNewsContent(newsfeed.objectid,newsfeed.type)">
    <img [src]="getUrl(newsfeed.mainimage) | secure | async" alt="content" id="content">
  </div>


  <div class="newsfeed-footer" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">

    <div class="like">
      <button mat-icon-button matTooltip="Like" [matTooltip]="newsfeed?.entity.is_liked? 'Unlike': 'Like'"
        (click)="likeUnlike(newsfeed)">
        <mat-icon *ngIf="!(newsfeed?.entity.is_liked)" class="edit-button" svgIcon="like">
        </mat-icon>
        <mat-icon *ngIf="newsfeed?.entity.is_liked" class="edit-button" svgIcon="liked">
        </mat-icon>
      </button>
      
      <mat-hint>&nbsp; {{numberOfLikes}} {{newsfeed?.entity.is_liked? 'Liked': 'Like'}}</mat-hint>
    </div>

    <div class="comment">
      <button mat-icon-button matTooltip="Comment" [matMenuTriggerFor]="postComment" #postTrigger="matMenuTrigger">
        <mat-icon class="edit-button" svgIcon="comment"></mat-icon>
      </button>

      {{newsfeed?.entity.commentcount }}
      <mat-hint matTooltip="Read Comments" [matMenuTriggerFor]="readComments"
        (click)="getComments(newsfeed?.objectid)" #commentTrigger="matMenuTrigger">&nbsp;Comments
      </mat-hint>
    </div>

    <div class="shre">
      <button mat-icon-button matTooltip="Share" [matMenuTriggerFor]="shareMenu"
        #shareTrigger="matMenuTrigger">
        <mat-icon class="edit-button" svgIcon="share"></mat-icon>
      </button>
      <mat-hint>Share</mat-hint>
    </div>

    <div>
      <button mat-icon-button matTooltip="Send" [matMenuTriggerFor]="sendMenu" #sendTrigger="matMenuTrigger">
        <mat-icon class="edit-button" svgIcon="send"></mat-icon>
      </button>
      <mat-hint>Send</mat-hint>
    </div>

  </div>

  <mat-menu #readComments="matMenu" class="custom-mat-menu">
    <div>
      <div *ngFor="let comment of comments" class="comment-unit">
        <mat-hint>{{comment.ts | date: 'medium'}}</mat-hint>
        <div>{{comment.comment}}</div>
      </div>
    </div>
  </mat-menu>

  <mat-menu #postComment="matMenu" class="custom-mat-menu">
    <form [formGroup]="commentForm" style="padding: 0 20px" fxLayout="column"
      (click)="$event.stopPropagation()" fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-form-field class="custom-form-field" appearance="outline">
        <mat-label>Comment</mat-label>
        <textarea matInput type="text" placeholder="Write a comment" formControlName="comment"></textarea>
      </mat-form-field>

      <button type="button" mat-stroked-button
        (click)="comment(newsfeed?.objectId,postTrigger)">Comment</button>
    </form>
  </mat-menu>

  <mat-menu #shareMenu="matMenu" class="custom-mat-menu">
    <form [formGroup]="shareForm" style="padding: 0 20px" (click)="$event.stopPropagation()">
      <p>Share to:</p>
      <div fxLayout="column" fxLayoutAlign="start center">
        <ng-select [items]="myNetwork" class="custom-ng-select" appearance="outline" appendTo="body"
          bindValue="uuid" bindLabel="name" [clearable]="true" formControlName="mynetwork"
          [notFoundText]="'No Results'" typeToSearchText="Search" placeholder="My Network">
        </ng-select>

        <button type="button" mat-stroked-button (click)="share(shareTrigger)">Share</button>
      </div>
    </form>
  </mat-menu>

  <mat-menu #sendMenu="matMenu" class="custom-mat-menu">
    <form [formGroup]="sendForm" style="padding: 0 20px" (click)="$event.stopPropagation()">
      <p>Send to:</p>
      <div fxLayout="column" fxLayoutAlign="start center">
        <ng-select [items]="myNetwork" class="custom-ng-select" appearance="outline" appendTo="body"
          bindValue="uuid" bindLabel="name" [clearable]="true" formControlName="userid"
          [notFoundText]="'No Results'" typeToSearchText="Search" placeholder="Person">
        </ng-select>

        <button type="button" mat-stroked-button (click)="send(sendTrigger)">Send</button>
      </div>
    </form>
  </mat-menu>


</div>
