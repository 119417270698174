import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-browse-categories-dialog',
  templateUrl: './browse-categories-dialog.component.html',
  styleUrls: ['./browse-categories-dialog.component.scss'],
})
export class BrowseCategoriesDialogComponent implements OnInit {
  categories = ['Contest', 'Project', 'Article', 'Entity', 'Mentor', 'Expert'];
  entityId;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    public hostElement: ElementRef,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit() {
    this.entityId = this.data.entityId;
  }

  filterByCategory(category): any {
    let queryParams = {};
    queryParams['mainCategory'] = category;
    this.router.navigate(['/browse', queryParams]).then(() => {
      window.location.reload();
    });
    this.dialog.closeAll();
  }
}
