<mat-tab-group
  mat-align-tabs="start"
  [selectedIndex]="selectedCategoryIndex"
  (selectedTabChange)="onTabChanged($event)"
>
  <mat-tab *ngFor="let categoryName of categoriesNames" [label]="categoryName">
    <div
      class="row"
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutGap="0px"
      fxLayoutGap.xs="20px"
      style="padding: 20px"
    >
      <div fxFlex="30">
        <h2>Filters</h2>
        <form
          [formGroup]="formGroupCategory"
          *ngIf="formGroupCategory"
          class="filter"
        >
          <div *ngFor="let filter of categoryData?.filters" class="filters">
            <p>{{ filter.name }}</p>
            <div [ngSwitch]="filter.type">
              <div *ngSwitchCase="'dropdown'">
                <mat-form-field>
                  <input
                    matInput
                    [formControlName]="filter.key"
                    [matAutocomplete]="auto1"
                  />
                  <mat-autocomplete #auto1="matAutocomplete">
                    <mat-option
                      *ngFor="let dropdownValue of filter.dropdownValues"
                      [value]="dropdownValue"
                    >
                      {{ dropdownValue }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div *ngSwitchCase="'text'">
                <mat-form-field>
                  <input matInput [formControlName]="filter.key" />
                </mat-form-field>
              </div>
              <div *ngSwitchCase="'tags'">
                <mat-form-field>
                  <mat-chip-list #chipList [formControlName]="filter.key">
                    <mat-chip
                      *ngFor="let tag of filter.tagsValues"
                      [selectable]="filter.selectable"
                      [removable]="filter.removable"
                      (removed)="remove(tag, filter.key)"
                    >
                      {{ tag }}
                      <mat-icon matChipRemove *ngIf="filter.removable"
                        >cancel</mat-icon
                      >
                    </mat-chip>
                    <input
                      placeholder="Enter keyword here... - Press ENTER or ' , ' to seperate"
                      [matChipInputFor]="chipList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      [matChipInputAddOnBlur]="filter.addOnBlur"
                      (matChipInputTokenEnd)="add($event, filter.key)"
                    />
                  </mat-chip-list>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div>
            <button
              mat-flat-button
              type="submit"
              (click)="submit(categoryName)"
              style="color: #008d91; border: 1px solid"
            >
              Filter
            </button>

            <button
              mat-flat-button
              type="submit"
              (click)="removeFilters(categoryName)"
            >
              Remove Filters
            </button>
          </div>
        </form>
      </div>

      <div 
        fxFlex="70" 
        *ngIf="categoryData?.results.length > 0 && !categoryData.isProfile"   
        infiniteScroll
        [infiniteScrollDistance]="10"
        [infiniteScrollThrottle]="1000"
        (scrolled)="onScroll()"
        [scrollWindow]="false"
        style="height: calc(100vh - 250px); overflow-y: scroll; overflow-x: none;"
      >
        <h2>Top Results</h2>
        <div
          *ngFor="let result of categoryData?.results"
          class="result-wrapper"
          (click)="redirectToResult(result)"
          style="cursor: pointer;"
        >
          <h2>{{ result.title }}</h2>

          <small class="category">{{ result.category }}</small>
          <small class="subcategory" *ngIf="result.subCategory">{{
            result.subCategory
          }}</small>
          <div class="result-list" *ngIf="result.authors?.length > 0">
            <span style="font-weight: bold" class="result-data">Authors:</span>
            <span *ngFor="let author of result.authors">
              {{ author }}
            </span>
          </div>

          <div *ngIf="result.requirements?.length > 0" style="margin-top: 10px">
            <span style="font-weight: bold">Requirements</span>

            <div *ngFor="let requirement of result.requirements">
              <div
                style="margin-top: 5px; display: flex"
                *ngIf="requirement['education']"
              >
                <i class="result-data" style="margin-right: 2px">Education: </i>
                <div *ngIf="categoryName == 'Contest'">
                  <span>
                    {{ requirement["education"] }}
                  </span>
                </div>
              </div>
              <div style="margin-top: 5px" *ngIf="requirement['skills']">
                <i class="result-data">Skills:</i>
                <span
                  *ngFor="let item of requirement['skills']"
                  class="result-data"
                >
                  {{ item.name }}
                </span>
              </div>

              <div style="margin-top: 5px" *ngIf="requirement['experience']">
                <i class="result-data">Experience</i>
                <div [innerHTML]="requirement['experience']"></div>
              </div>
            </div>
          </div>
          <div style="margin-top: 20px" [innerHTML]="result.abstract"></div>
          <div style="margin-top: 20px">
            <span style="font-weight: bold" *ngIf="categoryName == 'Contest'">{{
              result.rewardHeader
            }}</span>
            <span style="font-weight: bold" *ngIf="categoryName == 'Project'"
              >Reward</span
            >
            <div [innerHTML]="result.reward"></div>
          </div>

          <div class="result-list" *ngIf="result.keywords?.length > 0">
            <span style="font-weight: bold" class="result-data">Keywords:</span>
            <span *ngFor="let keyword of result.keywords">
              {{ keyword }}
            </span>
          </div>
        </div>
      </div>

      <div 
        fxFlex="70" 
        *ngIf="categoryData?.results.length > 0 && categoryData.isProfile"
        infiniteScroll
        [infiniteScrollDistance]="50"
        [infiniteScrollThrottle]="1000"
        (scrolled)="onScroll()"
        [scrollWindow]="false"
        style="height: calc(100vh - 250px); overflow-y: scroll; overflow-x: none;"
      > 
        <h2>Top Results</h2>
        <div
          *ngFor="let result of categoryData?.results"
          class="result-wrapper"
          (click)="redirectToResult(result)"
          style="cursor: pointer;"
        >
          <h2>{{ result.firstName }} {{ result.lastName }}</h2>
          <div style="margin-top: 20px">{{ result.motivation }}</div>

          <div style="margin-top: 10px" *ngIf="result.university">
            <b>University</b>: {{ result.university }}
          </div>

          <div style="margin-top: 10px" *ngIf="result.degree">
            <b>Degree</b>: {{ result.degree }}
          </div>
          <div style="margin-top: 10px" *ngIf="result.fieldOfStudy">
            <b>Field of Study</b>: {{ result.fieldOfStudy }}
          </div>
          <div style="margin-top: 10px" *ngIf="result.company">
            <b>Company</b>: {{ result.company }}
          </div>
          <div style="margin-top: 10px" *ngIf="result.certification">
            <b>Certificate</b>: {{ result.certification }}
          </div>
        </div>
      </div>

      <div fxFlex="70" *ngIf="categoryData?.results.length === 0">
        <h2 style="text-align: center;">No data found!</h2>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
