<div class="container">

  <mat-toolbar>

    <mat-toolbar-row>

      <div class="row" style="width: 100%;">

      <div class="col-lg-2 col-md-3 col-sm-6 col-xs-6" style="width: unset;">
        <img alt="icon" src="../../assets/logo-final.svg" (click)="getUserName(menuTrigger)" class="logo"
          routerLink="dashboard">
      </div>

      <div class="search-bar col-lg-1 col-md-1 col-sm-6 col-xs-6" (click)="openBrowseDialog()" [fxShow.xs]="true" [fxHide]="true">
        <mat-form-field appearance="outline" style="width: 100%;" [fxShow.xs]="true" [fxHide]="true">
          <input type="text" matInput>
          <mat-icon matPrefix svgIcon="search"></mat-icon>
        </mat-form-field>
      </div>

      <div class="col-lg-10 col-md-9 col-sm-12 col-xs-12 align-self-end">
      <div class="row d-flex">

        <div class="search-bar col-lg-6 col-md-6 col-sm-12 col-xs-12" (click)="openBrowseDialog()" [fxShow.xs]="false">
          <mat-form-field appearance="outline" style="width: 100%;" [fxShow.xs]="false">
            <input type="text" matInput placeholder="Search">
            <mat-icon matPrefix svgIcon="search"></mat-icon>
          </mat-form-field>
        </div>


        <!--
        <div class="language-menu" [matMenuTriggerFor]="languageMenu" [fxHide.xs]="true" style="visibility: hidden;">
          <mat-icon svgIcon="language"></mat-icon>
          <p class="menu-text">English</p>
        </div>

        <mat-menu #languageMenu="matMenu" style="visibility: hidden;">
          <button mat-menu-item>English</button>
          <button mat-menu-item>French</button>
        </mat-menu>

        
        <div class="challenge-menu" (click)="openBrowseDialog()" [fxHide.xs]="true">
          <mat-icon svgIcon="challenge"></mat-icon>
          <p class="menu-text">Challenges</p>
        </div>
        -->

        <div class="profile-item col-lg-3 col-md-3 col-sm-6 col-xs-6" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger" [fxHide.xs]="true">
          <mat-icon class="activity-icon" svgIcon="activity"></mat-icon>
          <p class="menu-text">{{button_label}}</p>
          <mat-icon class="arrow-icon" svgIcon="d-arrow"></mat-icon>
        </div>
        <mat-menu #menu="matMenu">
          <div>
            <button mat-menu-item [matMenuTriggerFor]="profileMenu"
              (click)="getUserName(menuTrigger)">{{profile?.firstname}} {{profile?.lastname}}
            </button>

            <button mat-menu-item *ngFor="let entity of entityList" 
                [matMenuTriggerDisabled]="true"
                (click)="switchToEntity(entity.eid, menuTrigger)">
                {{entity.orgname}} >>
            </button>
          </div>
        </mat-menu>

        <mat-menu #profileMenu="matMenu" xPosition="before">

          <button mat-menu-item [routerLink]="'/profile'">
            <mat-icon>account_circle</mat-icon>
            <span>My Profile</span>
          </button>

          <!--
          <button mat-menu-item>
            <mat-icon>list</mat-icon>
            <span>My Tasks</span>
          </button>
          <button mat-menu-item>
            <mat-icon>email</mat-icon>
            <span>My Inbox</span>
          </button>
          -->

          <button mat-menu-item (click)="logout()">
            <mat-icon>exit_to_app</mat-icon>
            <span>Logout</span>
          </button>
        </mat-menu>


        <div class="hamburger col-sm-2 col-xs-3" [matMenuTriggerFor]="menu" [fxShow.xs]="true" [fxHide]="true">
          <button mat-mini-fab class="hover-color">
            <mat-icon>reorder</mat-icon>
          </button>
        </div>

        <!--
        <div [fxHide]="true" [fxShow.xs]="false">
          <button mat-icon-button class="hover-color text" (click)="secondToolbar= !secondToolbar">
            <mat-icon>more_horiz</mat-icon>
          </button>
        </div>
        -->

        <div class="notification-item col-lg-1 col-md-1 col-sm-2 col-xs-3 ">
          <button mat-mini-fab class="hover-color" (click)="openNotificationsDialog()">
            <mat-icon [matBadge]="notificationCount" matBadgeSize="small" matBadgePosition="top after" svgIcon="bell">
            </mat-icon>
          </button>
        </div>

        <div class="message-item  col-lg-1 col-md-1 col-sm-2 col-xs-3 " [fxShow.xs]="false">
          <button mat-mini-fab class="hover-color" (click)="openMailDialog()">
            <mat-icon [matBadge]="messagesCount" matBadgeSize="small" matBadgePosition="top after" svgIcon="message">
            </mat-icon>
          </button>
        </div>

        <!-- *ngIf="isEntity" -->
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" *ngIf="false" style="cursor: pointer"
          [routerLink]="'entity/homepage/'+ entityId">
          <h1 style="margin: 0">{{userName}}</h1>
          <img [src]="pictureUrl | secure | async" style="max-height: 30px" *ngIf="hasLogo">
        </div>

        </div>
        </div>
      </div>
    </mat-toolbar-row>

    <!--Mobile toolbar-->
    <mat-toolbar-row [fxHide]="true" [fxShow.xs]="secondToolbar" fxLayout="row" fxLayoutAlign="space-around center">

      <div class="border-space-mobile" fxLayoutAlign="center center">
        <button mat-icon-button class="hover-color text" (click)="openNotificationsDialogMobile()">
          <mat-icon [matBadge]="notificationCount" matBadgeSize="small" matBadgePosition="below after"
            matBadgeColor="accent">
            notifications_none
          </mat-icon>
        </button>
      </div>

      <div class="border-space-mobile" fxLayoutAlign="center center">
        <button mat-icon-button class="hover-color text" (click)="openMailDialogMobile()">
          <mat-icon [matBadge]="messagesCount" matBadgeSize="small" matBadgePosition="below after"
            matBadgeColor="primary">
            mail_outline
          </mat-icon>
        </button>
      </div>

      <!--
      <div class="border-space-mobile" fxLayoutAlign="center center">
        <button mat-icon-button class="hover-color text" [matMenuTriggerFor]="languageMenu">
          <mat-icon style="width: 20px; vertical-align: middle">language</mat-icon>
        </button>

        <mat-menu #languageMenu="matMenu">
          <button mat-menu-item>
            <span>English</span>
          </button>
          <button mat-menu-item>
            <span>French</span>
          </button>
        </mat-menu>
      </div>
      -->

      <div class="hover-color border-space-mobile text" fxLayoutAlign="center center" (click)="openChallengesDialogMobile()">
        Challenges
        <mat-icon style="width: 20px; vertical-align: middle">keyboard_arrow_down</mat-icon>
      </div>

      <div class="border-space-mobile" fxLayoutAlign="center center">
        <button mat-icon-button class="hover-color text" [matMenuTriggerFor]="menu">
          <mat-icon style="width: 20px; vertical-align: middle">account_circle</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          <button mat-menu-item>
            <mat-icon>account_circle</mat-icon>
            <span>My Profile</span>
          </button>
          <button mat-menu-item>
            <mat-icon>list</mat-icon>
            <span>My Tasks</span>
          </button>
          <button mat-menu-item>
            <mat-icon>email</mat-icon>
            <span>My Inbox</span>
          </button>
          <button mat-menu-item (click)="logout()">
            <mat-icon>exit_to_app</mat-icon>
            <span>Logout</span>
          </button>
        </mat-menu>
      </div>


      <button mat-icon-button class="hover-color text" (click)="toggle()">
        <mat-icon>reorder</mat-icon>
      </button>
    </mat-toolbar-row>

  </mat-toolbar>

  <mat-sidenav-container class="sidenav-container" hasBackdrop="false">

    <mat-sidenav #snav mode="over" position="end" fixedTopGap="64" class="sidenav" [(opened)]="opened">

      <ng-container *ngIf="settingsOpened">
        <mat-tab-group mat-align-tabs="center">
          <mat-tab>
            <ng-template mat-tab-label class="tabs">
              <mat-icon>chat_bubble</mat-icon>
            </ng-template>
            Content 100
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label class="tabs">
              <mat-icon>notifications</mat-icon>
            </ng-template>
            Content 200
          </mat-tab>
          <mat-tab class="tabs">
            <ng-template mat-tab-label class="tabs">
              <mat-icon>settings</mat-icon>
            </ng-template>
            Content 300
          </mat-tab>
        </mat-tab-group>
      </ng-container>

      <ng-container *ngIf="!settingsOpened">
        <mat-tab-group mat-align-tabs="center">
          <mat-tab>
            <ng-template mat-tab-label class="tabs">
              <mat-icon>chat_bubble</mat-icon>
            </ng-template>
            Content 1
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label class="tabs">
              <mat-icon>notifications</mat-icon>
            </ng-template>
            Content 2
          </mat-tab>
          <mat-tab class="tabs">
            <ng-template mat-tab-label class="tabs">
              <mat-icon>settings</mat-icon>
            </ng-template>
            Content 3
          </mat-tab>
        </mat-tab-group>
      </ng-container>

    </mat-sidenav>

    <mat-sidenav-content>
      <router-outlet (activate)="activatedComponent($event)"></router-outlet>
      <!--  <button mat-raised-button class="float text" (click)="toggleSettings()" [class.float-left]="settingsOpened">
          <mat-icon>settings</mat-icon>
        </button> -->

    </mat-sidenav-content>

  </mat-sidenav-container>

</div>
