import { Component, ElementRef, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardService } from '../../_services/dashboard.service';

@Component({
  selector: 'app-manage-notifications',
  templateUrl: './manage-notifications.component.html',
  styleUrls: ['./manage-notifications.component.scss']
})
export class ManageNotificationsComponent implements OnInit {

  @ViewChildren('lastElement', { read: ElementRef })
  lastElement: QueryList<ElementRef>;
  observer: any;

  entityId;

  notifications;
  notificationCount;
  loadMore = 0;

  constructor(
    private service: DashboardService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) { 
  }

  ngOnInit(): void {
    this.allNotifications();
    this.intersectionObserver();
  }

  allNotifications(): any {

    // get entity ID:
    this.service.getUserProfile().subscribe(resp => {
      let profile = resp.data;
      this.entityId = profile.entityid;

      // get notifications:
      this.service.postNotifications(this.entityId, this.loadMore).subscribe(resp => {
        this.notifications = resp.data.notifications;
        this.notificationCount = resp.data.notificationscount;
      });

    });
    
  }

  ngAfterViewInit() {
    this.lastElement.changes.subscribe((d) => {
      if (d.last) this.observer.observe(d.last.nativeElement);
    });
  }

  intersectionObserver() {

    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.01,
    };

    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.loadMore += 2;
        
        this.service.postNotifications(this.entityId, this.loadMore).subscribe(resp => {
          this.notifications = resp.data.notifications;
          this.notificationCount = resp.data.notificationscount;
        });
      }
    }, options);
  }

  markRead(notifid): any {

    this.service.postMarkReadNotifications(this.entityId, notifid).subscribe(() => {
      this.service.postNotifications(this.entityId, this.loadMore).subscribe(resp => {
        this.notifications = resp.data.notifications;
        this.notificationCount = resp.data.notificationscount;
      });
    });
  }

  markAllRead(): any {
    
    this.service.postNotifications(this.entityId, this.notificationCount+20).subscribe(resp => {
      this.notifications = resp.data.notifications;

      for (let notification of this.notifications) {
        if (!notification.is_read) 
          this.service.postMarkReadNotifications(this.entityId, notification.notifid).subscribe(() => { });
      }

      this.service.postNotifications(this.entityId, this.loadMore).subscribe(resp => {
        this.notifications = resp.data.notifications;
        this.notificationCount = resp.data.notificationscount;
      });

    });

  }


}
