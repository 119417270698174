
<div class="notification-header" fxLayout="row" fxLayoutAlign="space-between center">
  <h5 style="font-size: .875rem; color:#39527b;margin-bottom: 2px">You Have {{notificationCount}} Unread
    Notification</h5>
  <button mat-button (click)="seeAllButton()">See all</button>
</div>

<div *ngFor="let notification of notifications" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">

  <div *ngIf="!notification.is_read" class="notification-unread">
    <mat-card-header>
      
      <mat-card-title>
        <div>
          <h6 class="notification-subject">{{notification.subject}}</h6>
          <p class="notification-date">{{notification.ts | date: 'MMM d, y, h:mm a'}}</p>
        </div>
      </mat-card-title>
      <span fxFlex></span>
        <div class="button">
          <button mat-icon-button (click)="markRead(notification.notifid)" [matTooltip]="'Mark as read'"><mat-icon>done</mat-icon></button>
        </div>

    </mat-card-header>
  </div>

  <div *ngIf="notification.is_read" class="notification-read">
    <div>
      <h6 class="notification-subject">{{notification.subject}}</h6>
      <p class="notification-date">{{notification.ts | date: 'MMM d, y, h:mm a'}}</p>
    </div>
  </div>
  
</div>

