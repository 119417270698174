<div id="notfound">
    <div class="notfound">
        <div class="notfound-404">
            <!--<h1>4<span></span>0</h1>-->
            <h1>403</h1>
        </div>
        <!--        <h2>Forbidden</h2>-->
        <p>{{message}}</p>
        <button mat-raised-button id="btn" (click)="goBack()">
            GO BACK
        </button>
    </div>
</div>

