import {Component, ElementRef, Inject, OnInit} from '@angular/core';
import {DashboardService} from '../../_services/dashboard.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ManageNotificationsComponent } from '../manage-notifications/manage-notifications.component';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss'],
  providers: [ManageNotificationsComponent]
})
export class NotificationDialogComponent implements OnInit {

  notificationCount;
  notifications;
  profilePictureUrl = `${environment.API_HOST}/files/profilepicture/`;

  entityId;

  constructor(private service: DashboardService,
              private dialogRef: MatDialogRef<NotificationDialogComponent>,
              public hostElement: ElementRef,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) private data: any) {
    this.notificationCount = data['data']['notificationscount'];
    this.notifications = data['data']['notifications'];
  }

  notificationResponse;
  notificationReloadRate;
  notificationDialogRef: MatDialogRef<ManageNotificationsComponent>;


  ngOnInit(): void {
    this.getNotifications();
    this.notificationReloadRate = setInterval(() => this.getNotifications(), 1000);
  }

  getNotifications(): any {

    this.service.getUserProfile().subscribe(resp => {
      let profile = resp.data;
      this.entityId = profile.entityid;

      this.service.getNotifications().subscribe(resp => {
        this.notificationResponse = resp;
        this.notifications = resp.data.notifications;
        this.notificationCount = resp['data']['notificationscount'];
        this.notificationReloadRate = resp['data']['reloadrate'];
      });

    });

  }

  getUrl(id): any {
    return this.profilePictureUrl + id;
  }

  seeAllButton(): any {

    this.dialog.open(ManageNotificationsComponent, { 
      width: '30%',
      height: '80%',
      autoFocus: false,
      data: this.notificationResponse
    }).afterClosed().subscribe( () => {
      this.getNotifications();
    });

    this.dialogRef.close();
  }

  markRead(notifid): any {
    this.service.postMarkReadNotifications(this.entityId, notifid).subscribe(resp => {
      this.service.getNotifications().subscribe(resp => {
        this.notifications = resp.data.notifications;
        this.notificationCount = resp.data.notificationscount;
      });
    });
  }

}
