import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../environments/environment';

declare var ga: Function; // Declare the global Google Analytics function

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  constructor(private router: Router) {}

  trackPageViews() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && environment.production) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });
  }
}

//G-M9E1PS4M6W
//Google tracking code
