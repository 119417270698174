<div class="container">

  <!--
  <div class="back-button">
    <button mat-stroked-button class="action-btn search-bar-button" (click)="goBack()">
      <mat-icon>arrow_back</mat-icon>
      <span>Go Back</span>
    </button>
  </div>
  -->

  <mat-horizontal-stepper #stepper linear>

    <form [formGroup]="form" (ngSubmit)="submit()" autocomplete="off">

      <mat-step label="Type" [completed]="form.controls['orgtype'].value">

        <mat-radio-group class="organization-type" aria-label="Select an option" formControlName="orgtype"
          fxLayout="column" fxLayoutGap="20px">
          <mat-radio-button *ngFor="let type of orgTypes" [value]="type.orgtype">{{type.orgtype}}</mat-radio-button>
        </mat-radio-group>

        <div class="footer-button">
          <button type="button" class="right-button" matStepperNext mat-raised-button>
            Next <mat-icon>arrow_forward</mat-icon>
          </button>
        </div>

      </mat-step>

      <mat-step>
        <ng-template matStepLabel>Identity</ng-template>

        <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">

          <div class="detail" fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutAlign="center start">
            <mat-form-field appearance="outline">
              <mat-label>Name</mat-label>
              <input matInput placeholder="Organization Name" formControlName="orgname">
              <mat-error *ngIf="form.controls['orgname'].hasError('serverError')">
                {{form.controls['orgname'].getError('serverError')}}
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Website</mat-label>
              <input matInput placeholder="Website" formControlName="website">
              <mat-error *ngIf="form.controls['website'].hasError('serverError')">
                {{form.controls['website'].getError('serverError')}}
              </mat-error>
            </mat-form-field>
          </div>

          
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Headline</mat-label>
              <input matInput placeholder="Headline to display" formControlName="headline">
              <mat-error *ngIf="form.controls['headline'].hasError('serverError')">
                {{form.controls['headline'].getError('serverError')}}
              </mat-error>
            </mat-form-field>


          <button class="add-button" (click)="addPublicUrl()" type="button" mat-raised-button>
            <mat-icon>add</mat-icon>
            Public Url
          </button>

          <div formArrayName="publicurls">
            <div *ngFor="let publicurl of form.get('publicurls')['controls']; let i=index">
              <div class="public-url" [formGroupName]="i" fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px"
                fxLayoutAlign="center start">

                <!--Name-->
                <mat-form-field appearance="outline">
                  <mat-label>Name</mat-label>
                  <input type="text" matInput formControlName="name">
                </mat-form-field>

                <!--Date-->
                <mat-form-field appearance="outline">
                  <mat-label>Url</mat-label>
                  <input type="text" matInput formControlName="url">
                </mat-form-field>

                <button (click)="removePublicUrl(i)" mat-icon-button
                  [disabled]="form.get('publicurls')['controls'].length == 1">
                  <mat-icon [class.delete-button]="form.get('publicurls')['controls'].length !== 1">delete</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>


        <div *ngFor="let i of list" class="logo-container">
          <img [src]="getUrl() | secure | async" style="max-width: 100%">
        </div>

        <div class="footer-button">
          <input #input1 class="hidden-input" type="file" accept="image/*" (change)="handleFileInput($event)">
          <button type="button" mat-flat-button (click)="input1.click()" [class.spinner]="loading">
            <mat-icon>insert_photo</mat-icon>
            {{!isMobile? 'Upload Image' : ''}}
          </button>

          <button mat-raised-button class="backward-button" matStepperPrevious type="button">
            <mat-icon>arrow_backward</mat-icon>
            Back
          </button>

          <button type="button" matStepperNext mat-raised-button>
            Next <mat-icon>arrow_forward</mat-icon>
          </button>
        </div>

      </mat-step>

      <mat-step label="Details">

        <div fxLayout="column" fxLayoutAlign="center center">

          <div appearance="outline" fxFlex *ngIf="isUpdate==true">
            <mat-label>Industry:</mat-label>
            <mat-label style="font-weight: 900;">{{entity.industry}}</mat-label>
          </div>

          <div appearance="outline" fxFlex *ngIf="isUpdate==true">
            <mat-label>Legal Type:</mat-label>
            <mat-label style="font-weight: 900;">{{entity.legaltype}}</mat-label>
          </div>

          <mat-form-field appearance="outline" fxFlex *ngIf="!isEducational">
            <mat-label>Industry</mat-label>
            <input type="text" placeholder="Search for industry" matInput formControlName="industry" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let industry of industries | async" [value]="industry">
                {{industry}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="form.controls['industry'].hasError('serverError')">
              {{form.controls['industry'].getError('serverError')}}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex *ngIf="isEducational">
            <mat-label>Academic Disciplines</mat-label>
            <input type="text" placeholder="Search for academic discipline" matInput
              formControlName="academicdiscipline" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let discipline of disciplines | async" [value]="discipline">
                {{discipline}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="form.controls['academicdiscipline'].hasError('serverError')">
              {{form.controls['academicdiscipline'].getError('serverError')}}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex
            *ngIf="form.controls['orgtype'].value === 'Small enterprise' || form.controls['orgtype'].value === 'Medium / large enterprise' ">
            <mat-label>Legal Type</mat-label>
            <mat-select formControlName="legaltype">
              <mat-option *ngFor="let type of orgLegalTypes" [value]="type.legaltype">
                {{type.legaltype}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.controls['legaltype'].hasError('serverError')">
              {{form.controls['legaltype'].getError('serverError')}}
            </mat-error>
          </mat-form-field>


          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Description</mat-label>
            <textarea matInput placeholder="Description" formControlName="description" style="height: 100px;"></textarea>
            <mat-error *ngIf="form.controls['description'].hasError('serverError')">
              {{form.controls['description'].getError('serverError')}}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="footer-button" style="margin-top: 5%;">

          <button mat-raised-button class="backward-button" matStepperPrevious type="button">
          <mat-icon>arrow_backward</mat-icon>Back</button>

          <button *ngIf="isUpdate == false" class="add-btn" mat-raised-button (click)="submit()" matStepperNext type="submit">Save</button>

          <button *ngIf="isUpdate == true" class="add-btn" mat-raised-button (click)="updateEnity()" matStepperNext type="submit">Update</button>

        </div>

      </mat-step>
    </form>
  </mat-horizontal-stepper>

</div>
