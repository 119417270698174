<form [formGroup]="skillsForm" (ngSubmit)="submit()">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h2 style="margin: 0">{{ data ? 'Edit Skills' : 'Add Skills'}}</h2>
  </div>
  <mat-divider></mat-divider>

  <div style="margin-top: 20px">
    <mat-form-field fxFlex>
      <mat-label>Industry</mat-label>
      <input type="text"
             placeholder="Search for industry"
             matInput
             formControlName="industry"
             [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let industry of industries | async" [value]="industry">
          {{industry}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="chip-list" fxFlex>
      <mat-label>Skills</mat-label>
      <mat-chip-list #chipList aria-label="Skill selection">
        <mat-chip
          *ngFor="let skill of selectedSkills"
          [selectable]="selectable"
          [removable]="removable"
          (removed)="remove(skill)">
          {{skill}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
          placeholder="New skill..."
          #skillInput
          formControlName="skills"
          [matAutocomplete]="auto1"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)">
      </mat-chip-list>
      <mat-autocomplete #auto1="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let skill of skills | async" [value]="skill">
          {{skill}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
    <button mat-stroked-button type="button" (click)="close()">Cancel</button>
    <button mat-raised-button color="primary" type="submit">Save</button>
  </div>

</form>
