
<div fxLayout="row" fxLayoutAlign="space-between center" class="top-title">

    <div class="header">
      <div class="name" *ngIf="profile?.expert">{{profile?.expert.intro[0].firstname}} {{profile?.expert.intro[0].lastname}}</div>
      <div class="name" *ngIf="profile?.entity">{{profile?.entity.name}}</div>
    </div>

    <button mat-icon-button class="close-button" [mat-dialog-close]="true" (click)="closeChat(receiverId)">
      <mat-icon class="close-icon">close</mat-icon>
    </button>
  
</div>
<mat-divider></mat-divider>

<div #chatmessages class="messages">

  <div *ngFor="let msg of allMessages">

    <div *ngIf="(msg.senderid===senderId); then messagesSent else messagesReceived">
    </div>

    <ng-template #messagesSent>
      <div class="message-sent">
        <div class="message">
          {{msg.message}}
        </div>
      </div>
    </ng-template>
  
    <ng-template #messagesReceived>
      <div class="message-received">
        <div class="message">
          {{msg.message}}
        </div>
      </div>
    </ng-template>

  </div>
</div>


<br/>

<div class="messaging">
  <mat-divider></mat-divider>
  <mat-form-field class="write-msg" floatLabel="never">
    <input matInput ng-model="messageInput" #messageInput placeholder="Type a message" (keyup.enter)="sendMessage(messageInput.value, messageInput.id)">
    <mat-icon matSuffix (click)="sendMessage(messageInput.value, messageInput.id)" style="cursor: pointer;">send</mat-icon>
  </mat-form-field>
</div>

<!-- <div *ngIf="message.profilepicture">
    <img [src]="getUrl(message.profilepicture) | secure | async" alt="photo"
         style="width: 50px;height: 50px">
  </div>  -->

<!--  <div *ngFor="let message of messages" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">

  <div class="message-unread">
    <div>
      <h6 class="message-header">{{message.subject}}</h6>
      <p class="date">{{message.ts | date: 'MMM d, y, h:mm a'}}</p>
    </div>
  </div>

</div>  -->

